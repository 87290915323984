import React from 'react';
import "./handler.scss";

const Handler = () => {
  return (
    <div className="handler">
        <span className="handler--shape"></span>
    </div>
  )
}

export default Handler
